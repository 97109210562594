import vue from 'vue'
import VueI18n from 'vue-i18n'

vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: 'es',
    fallbackLocale: 'en',
    messages: {
        es: {
            btnHome: 'Inicio',
            btnMenu: 'Menú',
            btnContact: 'contacto',
            btnHomeService: 'servicio a domicilio',
            btnReservations: 'reservaciones',
            titleLocationSchedule: 'UBICACIÓN Y HORARIOS',
            titleMenuHome: 'clásicos',
            btnMenuText: 'VER MENÚ COMPLETO',
            btnSeeLocation: 'VER UBICACIÓN',
            ad: 'próximamente',
            firstWordIntro: 'DESFORMAL.',
            secondWordIntro: 'EXTROVERTIDO.',
            thirdWordIntro: 'RESONANTE.',
            fourthWordIntro: 'ATEMPORAL.',
            firstDays: 'lunes a jueves',
            secondDays: 'viernes y sábado',
            otherDays: 'domingo',
            GuadalajaraDays1: 'Lunes, Martes y Miércoles',
            GuadalajaraDays2: 'Jueves, Viernes y Sábados',
            GuadalajaraDays3: 'Domingos',
            instagram: 'srtanakamty',
            facebook: 'srtanakamty',
            firstLine: 'El gramaje corresponde al total del platillo en crudo.',
            secondLine: 'El consumo de proteína cruda es bajo responsabilidad del comensal.',
            thirdLine: 'Debido a que manejamos productos frescos, algunos de nuestro platillos',
            fourthLine: 'Podrían no estar disponibles.'
        },
        en: {
            btnHome: 'Home',
            btnMenu: 'Menu',
            btnContact: 'contact',
            btnHomeService: 'delivery',
            btnReservations: 'rsvp',
            titleLocationSchedule: 'LOCATION AND HOURS OF OPERATION',
            titleMenuHome: 'classics',
            btnMenuText: 'SEE FULL MENU',
            btnSeeLocation: 'SEE LOCATION',
            ad: 'coming soon',
            firstWordIntro: 'INFORMAL.',
            secondWordIntro: 'OUTGOING.',
            thirdWordIntro: 'RESONANT.',
            fourthWordIntro: 'TIMELESS.',
            firstDays: 'monday to thursday',
            secondDays: 'friday and saturday',
            otherDays: 'sunday',
            GuadalajaraDays1: 'monday, tuesday y wednesday',
            GuadalajaraDays2: 'thursday, friday y saturday',
            GuadalajaraDays3: 'sunday',
            instagram: 'senortanakamx',
            facebook: 'senortanakamx',
            firstLine: 'The grammage, corresponds to the total of the raw dish.',
            secondLine: 'The consumption of raw protein is under the responsibility of the diner.',
            thirdLine: 'Because we handle fresh produce, some of our dishes may not be available.',
            fourthLine: 'Prices include VAT and are in Mexican national currency.',
        }
    }
})